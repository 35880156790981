import { countdown } from './components/countdown'
import { input } from './components/input'
import { select } from './components/select'
//import { video } from './components/video'
import { drawer } from './components/drawer'
import { accordion } from './components/accordion'
//import { donation } from './components/forms/donation'
//import { newsletter } from './components/forms/newsletter'
//import { tiers } from './components/tiers'
//import { recentPosts } from './components/recentPosts'
import { toolBar } from './components/toolBar'

countdown()
input()
select()
//video()
drawer()
accordion()
//donation()
//newsletter()
//tiers()
//recentPosts()
toolBar()